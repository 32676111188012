import React, { useState, useLayoutEffect } from 'react';
import {
  Button,
  Switch,
  DateRangeInput,
  InputLabel,
} from '@increase/typed-components';
import { useEventTracker } from '@increasecard/increase-core';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import connect from 'react-redux/es/connect/connect';
import styled from 'styled-components';
import { PendingRecordsTables } from './DualTables';
import { NewManualMatchModal } from './NewManualMatchModal';
import { ConfirmManualMatchModal } from './ConfirmManualMatchModal';
import { PendingRecordsReportsModal } from './PendingRecordsReportsModal';
import {
  clearRecordsState,
  fetchColumns,
  fetchRowsA,
  fetchRowsB,
  filterAll,
  filterRowsA,
  filterRowsB,
  selectRowA,
  selectRowB,
  startManualMatching,
  finishManualMatching,
  addCurrentMatch,
  toggleTableLayout,
  fetchPerndingRecordsReports,
  generatePerndingRecordsReport,
  resendPendingRecordsReport,
  cleanPendingRecordsSate,
} from '../../../reducers/recordsReducer';
import {
  selectProfile,
  checkConciliationStatus,
} from '../../../reducers/profilesReducer';
import { fetchStructureMetadata } from '../../../reducers/fileStructureReducer';
import { FullScreenEmptyState, EmptyState } from '../../common/EmptyState';
import CruceImg from '../../../img/cruce.svg';
import { Pencil } from 'styled-icons/boxicons-solid';
import { ViewAgenda } from 'styled-icons/material/ViewAgenda';
import IconButton from '../../common/IconButton';
import { EVENTS } from '../../../actionTracker';

const ButtonStyled = styled(IconButton)`
  padding: 10px;
  transition: 0.25s;
  border-radius: 4px;
  & > svg {
    width: 20px;
    vertical-align: bottom;
    &.vertical {
      transform: rotate(90deg);
    }
  }
  &:last-child {
    margin-left: 0.5rem;
  }
  &:hover,
  &.selected {
    background: #d8d8d8;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'center'};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`;

const FiltersBar = styled(FlexContainer)`
  & > * {
    margin-left: 2rem;
  }
`;

function Records({
  dispatch,
  match: {
    params: { profileId },
  },
  onProfileSelection,
  fileStructureCached,
  ...rest
}) {
  const eventTracker = useEventTracker();

  const [startMatchingModalVisible, setStartMatchingModalVisible] = useState(
    false
  );
  const [finishMatchingModalVisible, setFinishMatchingModalVisible] = useState(
    false
  );
  const [generateReportState, setGenerateReportState] = useState({
    generateReportModal: false,
    dataPoolId: '',
  });

  // Prevent ui flicker with stale data by using useLayoutEffect
  useLayoutEffect(() => {
    dispatch(checkConciliationStatus(Number(profileId)));
    if (!fileStructureCached) {
      dispatch(fetchStructureMetadata())
        .then(() => {
          return onProfileSelection(Number(profileId));
        })
        .then(() => {
          dispatch(fetchColumns());
          dispatch(filterAll());
        });
    } else {
      dispatch(fetchColumns());
      dispatch(filterAll());
    }
  }, [fileStructureCached, dispatch, onProfileSelection, profileId]);

  const handleShowManualMatchingModal = () => {
    setStartMatchingModalVisible(true);
  };

  const handleShowFinishMatchingModal = () => {
    setFinishMatchingModalVisible(true);
  };

  const handleFinishManualMatching = () => {
    eventTracker(EVENTS.MANUAL_RECONCILIATION);
    rest.onFinishManualMatching();
    handleHideManualMatchingModal();
  };

  const handleStartManualMatching = (matchName) => {
    setStartMatchingModalVisible(false);
    rest.onStartManualMatching(matchName);
  };

  const handleHideManualMatchingModal = () => {
    setStartMatchingModalVisible(false);
    setFinishMatchingModalVisible(false);
  };

  const handleShowReportsModal = (dataPoolKey) => {
    const dataPoolId = rest.dataPools[dataPoolKey].id;
    rest.onGetPendingRecordsReports({ dataPoolId });
    setGenerateReportState({
      generateReportModal: true,
      dataPoolId,
    });
  };

  const handleHideReportsModal = () => {
    rest.onPendingReportGenerationFinished();
    setGenerateReportState({
      generateReportModal: false,
      dataPoolId: '',
    });
  };

  const generateReport = (receivers, reportId) => {
    const {
      onGeneratePendingRecordsReport,
      onResendPendingRecordsReport,
      generalFilters: { dateRange },
    } = rest;
    const { dataPoolId } = generateReportState;
    if (reportId) {
      return onResendPendingRecordsReport({ reportId, receivers });
    }
    return onGeneratePendingRecordsReport({ dataPoolId, receivers, dateRange });
  };

  const {
    dataPools,
    filterConciliated,
    filterDateRange,
    generalFilters,
    horizontal,
    disabledPage,
    manualMatchingStarted,
    onAddMatch,
    onTableLayoutChange,
    selectedA,
    selectedB,
    matchedItemsA,
    matchedItemsB,
    vsCard,
    isProfileMatching,
    reconciliationType,
    columnsA,
    columnsB,
    fetchingA,
    fetchingB,
    filtersA,
    filtersB,
    onFilterChangeA,
    onFilterChangeB,
    onPageASelect,
    onPageBSelect,
    onSelectChangeA,
    onSelectChangeB,
    pageA,
    pageB,
    pagesA,
    pagesB,
    rowsA,
    rowsB,
    fetching,
    t,
  } = rest;

  return (
    <>
      {disabledPage && (
        <FullScreenEmptyState description={t('empty_states.records')}>
          <Pencil size="72" />
        </FullScreenEmptyState>
      )}
      {isProfileMatching && (
        <EmptyState description={t('matching_on_course')}>
          <img alt="icono" src={CruceImg} />
        </EmptyState>
      )}
      <NewManualMatchModal
        onCancel={handleHideManualMatchingModal}
        onOk={handleStartManualMatching}
        visible={startMatchingModalVisible}
      />
      <ConfirmManualMatchModal
        onCancel={handleHideManualMatchingModal}
        onOk={handleFinishManualMatching}
        visible={finishMatchingModalVisible}
      />
      <PendingRecordsReportsModal
        creationSuccess={rest.reportSuccess}
        dataPoolId={generateReportState.dataPoolId}
        isLoading={rest.fetchingReports}
        onCancel={handleHideReportsModal}
        onOk={generateReport}
        reports={rest.reports}
        visible={generateReportState.generateReportModal}
      />
      <FlexContainer
        alignItems="flex-end"
        className="filter-bar"
        justifyContent="space-between"
      >
        <div>
          {!manualMatchingStarted ? (
            <Button
              buttonType="outline"
              onClick={handleShowManualMatchingModal}
            >
              {t('pending_operations.start_manual_matching')}
            </Button>
          ) : (
            <>
              <Button
                buttonType="outline"
                disabled={selectedA.length === 0 && selectedB.length === 0}
                onClick={onAddMatch}
              >
                {t('conciliate')}
              </Button>
              <Button
                buttonType="invisible"
                disabled={
                  matchedItemsA.length === 0 && matchedItemsB.length === 0
                }
                onClick={handleShowFinishMatchingModal}
              >
                {t('finish')}
              </Button>
            </>
          )}
        </div>
        <FiltersBar alignItems="flex-end">
          <div style={{ padding: '0.5rem' }}>
            <Switch
              checked={generalFilters.conciliated}
              id="filter-switch"
              label={t('pending_operations.show_all')}
              onChange={filterConciliated}
            />
          </div>
          <DateRangeInput
            label={
              vsCard
                ? t(`constants:increasecard_fields.${dataPools.referenceField}`)
                : dataPools.referenceField
            }
            onChange={filterDateRange}
            value={generalFilters.dateRange}
          />
          <FlexContainer flexDirection="column">
            <InputLabel style={{ marginBottom: '6px' }}>
              {t('visualization')}
            </InputLabel>
            <div>
              <ButtonStyled
                className={horizontal ? null : 'selected '}
                onClick={onTableLayoutChange}
              >
                <ViewAgenda className="vertical" />
              </ButtonStyled>
              <ButtonStyled
                className={horizontal ? 'selected' : null}
                onClick={onTableLayoutChange}
              >
                <ViewAgenda />
              </ButtonStyled>
            </div>
          </FlexContainer>
        </FiltersBar>
      </FlexContainer>
      <PendingRecordsTables
        columnsA={columnsA}
        columnsB={columnsB}
        dataPoolAName={dataPools.dataPoolA.name}
        dataPoolBName={dataPools.dataPoolB.name}
        disableReportGeneration={manualMatchingStarted}
        fetching={fetching}
        fetchingA={fetchingA}
        fetchingB={fetchingB}
        filtersA={filtersA}
        filtersB={filtersB}
        generalFilters={generalFilters}
        horizontal={horizontal}
        matchedItemsA={matchedItemsA}
        matchedItemsB={matchedItemsB}
        onFilterChangeA={onFilterChangeA}
        onFilterChangeB={onFilterChangeB}
        onGenerateReport={handleShowReportsModal}
        onPageASelect={onPageASelect}
        onPageBSelect={onPageBSelect}
        onSelectChangeA={onSelectChangeA}
        onSelectChangeB={onSelectChangeB}
        pageA={pageA}
        pageB={pageB}
        pagesA={pagesA}
        pagesB={pagesB}
        reconciliationType={reconciliationType}
        rowsA={rowsA}
        rowsB={rowsB}
        selectedA={selectedA}
        selectedB={selectedB}
        t={t}
        vsCard={vsCard}
      />
    </>
  );
}

Records.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  clearState: () => {
    return dispatch(clearRecordsState());
  },
  filterDateRange: (value) => {
    return dispatch(filterAll({ dateRange: value }));
  },
  filterConciliated: (e) => {
    return dispatch(filterAll({ conciliated: e.target.checked }));
  },
  onSelectChangeA: (rowA, isSelected) => {
    return dispatch(selectRowA(rowA, isSelected));
  },
  onSelectChangeB: (rowB, isSelected) => {
    return dispatch(selectRowB(rowB, isSelected));
  },
  onPageASelect: (pageA) => {
    dispatch({ type: 'SET_PAGE_A', pageA });
    return dispatch(fetchRowsA());
  },
  onPageBSelect: (pageB) => {
    dispatch({ type: 'SET_PAGE_B', pageB });
    return dispatch(fetchRowsB());
  },
  onFilterChangeA: (filterName, value) =>
    dispatch(filterRowsA({ [filterName]: value })),
  onFilterChangeB: (filterName, value) =>
    dispatch(filterRowsB({ [filterName]: value })),
  onProfileSelection: (profileId) => {
    return dispatch(selectProfile(profileId));
  },
  onStartManualMatching: (matchSessionName) =>
    dispatch(startManualMatching(matchSessionName)),
  onFinishManualMatching: () => dispatch(finishManualMatching()),
  onAddMatch: () => dispatch(addCurrentMatch()),
  onGeneratePendingRecordsReport: (params) =>
    dispatch(generatePerndingRecordsReport(params)),
  onGetPendingRecordsReports: (params) =>
    dispatch(fetchPerndingRecordsReports(params)),
  onResendPendingRecordsReport: (params) =>
    dispatch(resendPendingRecordsReport(params)),
  onPendingReportGenerationFinished: () => dispatch(cleanPendingRecordsSate()),
  onTableLayoutChange: () => dispatch(toggleTableLayout()),
});

function mapStateToProps(state) {
  const masquerading = state.auth.user.masquerading;
  const {
    disabledPage,
    profileId,
    fetching: fetchingProfile,
    profile,
    vsCard,
    isProfileMatching,
  } = state.profiles;
  const {
    cached: fileStructureCached,
    fetching: fetchinFileStructure,
  } = state.fileStructure;
  return {
    ...state.records,
    matchedItemsA: state.records.matches.reduce(
      (prev, curr) => [...prev, ...curr.dataPoolA],
      []
    ),
    matchedItemsB: state.records.matches.reduce(
      (prev, curr) => [...prev, ...curr.dataPoolB],
      []
    ),
    dataPools: state.dataPools,
    masquerading,
    profileId,
    fetching: fetchingProfile || fetchinFileStructure,
    disabledPage,
    reconciliationType: profile.reconciliationType,
    fileStructureCached,
    vsCard,
    isProfileMatching,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Records));
